<template>
  <v-container>
    <v-col style="margin: 20px auto 0" :cols="breakpoints">
      <div @click="changes = false">
            <v-row class="mb-4"
            style="display: flex;
            align-items:center;
            justify-content: space-between">
                <h1>{{ todoData.Title }}</h1>
                <div @click="deleteTodo()" style="width: 30px; height: 30px;"><v-icon>mdi-delete</v-icon></div>
            </v-row>
            <v-row cols="8">
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  label="Bearbeiter"
                  :items="users"
                  v-model="SelectedUser"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="Fällig"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="Fällig"
                      label="Fällig am"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              
            </v-row>
            <v-row cols="8">
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  label="Status der Aufgabe"
                  :items="Status"
                  v-model="CurrentStatus"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Beschreibung der Aufgabe"
                  v-model="todoData.Description"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-btn @click="aktualisieren()" :disabled = changes class="ml-3" color="primary" large>Aktualisieren</v-btn>
            </v-row>
      </div>
            <hr class="mt-10">
            <h3>Kommentare</h3>
            <v-col
            style="background-color: #efefef; border-radius: 5px;"
            class="mx-0 py-7 px-5 my-2"
            v-for="Kommentar in todoData.Comments"
            :key="Kommentar.index">
            <h4 class="py-2">{{ Kommentar.Comment }}</h4>
            <span style="color: #00000090">{{ Kommentar.User }}</span>
            </v-col>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                label="Kommentieren"
                v-model="NewKommentar"
                outlined></v-text-field>
              </v-col>
              <v-btn 
              v-show = NewKommentar
              class="ml-3" 
              color="primary" 
              @click="Comment()"
              large>Kommentar hinzufügen</v-btn>
            </v-row>
    </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
export default {
  data:() => {
    return {
      todoData: [],
      todoID: "",
      Fällig: "",
      users: [],
      SelectedUser: "",
      Status: ["Bereit zum Bearbeiten", "Wird bearbeitet", "Fertig"],
      CurrentStatus: "",
      changes: true,
      NewKommentar: "",
      UserName: localStorage.getItem('Username'),
    }
  },
  methods: {
      aktualisieren() {
        axios
        .patch(`https://kjg-api.rezept-zettel.de/api/todos/${this.todoID}`, {
          "Assignee": this.SelectedUser,
          "DueDate": this.Fällig,
          "Status": this.CurrentStatus,
          "Description": this.todoData.Description
        })
        .then(Response => {
          console.log(Response)
          Vue.$toast.open({
          message: 'Erfolgreich aktualisert',
          type: 'success',
        });
        })
        .catch((err) => {
        console.log(err)
        Vue.$toast.open({
          message: 'Versuche es später erneut.',
          type: 'error',
        });
      })
      },
    getData() {
      this.todoID = this.$route.params.id
      axios
      .get(`https://kjg-api.rezept-zettel.de/api/todos/${this.todoID}`)
      .then(Response => {
        this.todoData = Response.data
        this.SelectedUser = Response.data.Assignee
        this.CurrentStatus = Response.data.Status
        this.Fällig = Response.data.DueDate
      })
      .catch((err) => {
        console.log(err)
        Vue.$toast.open({
          message: 'Versuche es später erneut.',
          type: 'error',
        });
      })
    },
    FetchUser: async function() {
            axios
            .get("https://kjg-api.rezept-zettel.de/api/auth")
            .then(Response => {
                let Arr = [];
                for(var i = 0; i < Response.data.length; i++) {
                    Arr.push(Response.data[i].name)
                }
                this.users = Arr
            })
            .catch((err) => {
                console.log(err, err.stack)
            })
        },
        Comment() {
            axios
            .post("https://kjg-api.rezept-zettel.de/api/todos/comment/" + this.todoID, {
                "User": this.UserName,
                "Comment": this.NewKommentar
            })
            .then(Response => {
                console.log(Response)
                this.getData()
            })
            .catch((err) => {
                console.log(err)
            })
        },
        deleteTodo(){
          axios
          .delete("https://kjg-api.rezept-zettel.de/api/todos/" + this.todoID)
            .then(Response => {
                console.log(Response)
                this.$router.push('/todos')
                Vue.$toast.open({
                message: 'Aufgabe wurde gelöscht.',
                type: 'success',
              });
            })
            .catch((err) => {
                console.log(err)
            })
        },
  },
  created() {
    this.getData();
    this.FetchUser();
  },
  computed: {
    /* eslint-disable */
    breakpoints () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12
        case 'sm': return 12
        case 'md': return 8
        case 'lg': return 8
        case 'xl': return 6
      }
    },
  },
}
</script>

<style>

</style>